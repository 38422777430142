<template lang="pug">
.filter-template
  .filter-header(v-if="$slots.header")
    span.label
      slot(name="header")
    span.reset(@click="$emit('reset')") очистить
  .filter-content
    slot(name="content")
  .filter-sorting(v-if="showSorting")
    span.sort-title {{ sortLabel }}
    span.sort-order(:class="sortingOrder === 'desc' && '_active'" @click="$emit('setOrder', 'desc')") Убыванию
    span.sort-order(:class="sortingOrder === 'asc' && '_active'" @click="$emit('setOrder', 'asc')") Возрастанию
    span.sort-order(@click="$emit('setOrder', '')") Сбросить
  .filter-warning(v-if="showWarnings")
    span Установлен основной фильтр в расширенном поиске. Данный фильтр предназначен для дофильтрации результатов поиска.
  .filter-actions
    ui-button(type="secondary" @click="$emit('cancel')") Отменить
    ui-button(@click="$emit('save')") Применить
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UiButton from "@/components/ui/button/UiButton.vue";

export default defineComponent({
  name: "FilterTemplate",
  components: {
    UiButton,
  },
  emits: [
    'save',
    'cancel',
    'reset',
    'setOrder',
  ],
  props: {
    sortLabel: {
      type: String,
      default: 'Сортировать по:',
    },
    sortingOrder: {
      type: String,
      default: '',
    },
    showSorting: {
      type: Boolean,
      default: false,
    },
    showWarnings: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss">
.filter-template {
  display: flex;
  flex-flow: column;
  gap: 16px;

  font-size: 14px;
  max-width: 520px;

  padding: 16px 32px;
  margin-top: 2px;
  background-color: white;
  box-shadow: var(--dialog-border-shadow);
  color: var(--main-color-black);

  min-height: 100px;   // ???
  justify-content: space-between;   // ???

  .filter-header {
    display: flex;
    flex-flow: row;
    gap: 8px;
    align-items: baseline;

    .label {
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
      color: var(--main-color-black);
    }

    .reset {
      font-size: 13px;
      cursor: pointer;
      margin-left: auto;
      color: var(--main-color-blue);
    }
  }

  .filter-content {
    display: flex;
    flex-flow: column;
    gap: 16px;
  }

  .filter-sorting {
    display: flex;
    gap: 12px;
    font-size: 12px;
    justify-content: space-between;

    .sort-title {
      margin-right: 15px;
      font-size: 12px;
    }

    .sort-order {
      color: #3399cc;

      &:hover {
        cursor: pointer;
      }

      &._active {
        font-weight: bold;
      }
    }
  }

  .filter-warning {
    font-size: 12px;
    line-height: 15px;
    color: var(--main-red-color);
  }

  .filter-actions {
    display: flex;
    gap: 8px;
    align-self: flex-end;
  }
}
</style>
