<template lang="pug">
.header(:class="[type, sticky && 'sticky']" :style="type === 'filters' && `grid-template-columns: repeat(${ filtersCount }, minmax(0, 1fr)) ${ filtersCount < 4 ? `minmax(0, ${ 4 - filtersCount }fr)` : '' } 280px;`")
  template(v-for="(cellValue, cellName) in columns")
    .layout-wrapper(v-if="!cellValue.invisible" :key="cellName" :style="cellValue.size && `width: ${ cellValue.size }px`")
      filter-layout(
        v-if="cellValue.filterComponent"
        has-filter
        v-model:sorting="preFiltrationForm.sorting"
        :showSortIndex="type === 'filters'"
        :label="cellValue.headerLabel"
        :value="cellValue.value"
        :sort-name="cellName"
        :type="type"
        :placement="cellValue.placement"
        :raw-value="cellValue.valueAsHTML"
        :has-sort="cellValue.isSort"
        @apply="$emit('apply')"
      )
        template(#filter="{ close }")
          component(
            :is="cellValue.filterComponent"
            v-model="preFiltrationForm"
            @close="close"
            @apply="$emit('apply')"
          )
      filter-layout(
        v-else-if="cellValue.isSort"
        has-sort
        v-model:sorting="preFiltrationForm.sorting"
        :type="type"
        :label="cellValue.headerLabel"
        :sort-name="cellName"
        @apply="$emit('apply')"
      )
      .header-clear(v-else-if="cellName === 'clearIcon' && showClose")
        icon-button(
          :size="16"
          :icon="UiIconNames.Icon_Close"
          tooltip-title="Очистить фильтры"
          @click="$emit('clear')"
        )
      .header-cell(v-else :class="type") {{ cellValue.headerLabel }}

  .filter-panel__empty-block(v-if="type === 'filters' && filtersCount < 4")

  .filters-panel-actions(v-if="type === 'filters'")
    .action-button._reset(:class="hasActiveFilters && '_active'" @click="$emit('reset')")
      template(v-if="hasActiveFilters") Сбросить
    ui-button.action-button._save(
      v-if="enableDownloadLotsExcel"
      :icon-left="UiIconNames.Icon_Download"
      :loader="isDownloading"
      @click="$emit('download')"
    ) Скачать результаты
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useVModel } from "@vueuse/core";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";

import type { PropType } from 'vue';
import FilterLayout from "@/components/ui/filters/FilterLayout.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import IconButton from "@/components/ui/button/IconButton.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

interface HeaderColumnI {
  size: number,
  headerLabel?: string,
  bold?: boolean,
  value?: any,
  invisible?: boolean,
  isSort?: boolean,
  filterComponent?: any,
  placement?: string,
  valueAsHTML: boolean,
}

interface HeaderI {
  [name: string]: HeaderColumnI;
}

export default defineComponent({
  name: "FilterPanel",
  components: {
    IconButton,
    UiButton,
    FilterLayout,
  },
  emits: [
    'apply',
    'clear',
    'reset',
    'download',
    'update:modelValue',
  ],
  props: {
    type: {
      type: String as PropType<'default'|'simple'|'filters'>,
      default: 'default',
    },
    sticky: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Object as PropType<HeaderI>,
      default: () => {},
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    hasActiveFilters: {
      type: Boolean,
      default: false,
    },
    isDownloading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {

    const preFiltrationForm = useVModel(props, 'modelValue', emit);
    const { enableDownloadLotsExcel } = useUserAccess();

    const filtersCount = computed(() => Object.keys(props.columns).filter(e => !props.columns[e]?.invisible).length)

    return {
      preFiltrationForm,
      filtersCount,
      UiIconNames,
      enableDownloadLotsExcel,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/fonts';
$border-color: #c6e2ff;
$background-color: #ecf5ff;
$background-color-active: #d9ecff;

.header {
  display: flex;
  flex-flow: row;

  &.default, &.filters {
    //border-bottom: 1px solid #F1F0F0;

    padding-top: 8px;
    background-color: var(--main-color-white);

    position: sticky;
    top: 0;
    z-index: 2;
  }

  &.filters {
    display: grid;
    background-color: #ecf5ff;
    padding-top: 0;
    margin-bottom: 16px;
  }

  &.simple {
    //gap: 24px;
    //padding: 8px;
    //border-top: 1px solid #F1F0F0;
    border-bottom: 1px solid #F1F0F0;
    background-color: white;
    //margin-bottom: 16px;
  }

  &.sticky {
    position: sticky;
    top: 0;
  }
}

.header-cell {
  display: flex;
  flex-flow: row;
  gap: 4px;
  align-items: center;
  height: 36px;
  width: inherit;

  &.simple {
    @include label-12-16;
    color: #606266;
    padding: 8px;
    box-sizing: border-box;
    height: 100%;
  }

  &.default, &.filters {
    padding: 8px;
    background-color: var(--main-color-white);

    font-size: 13px;
    line-height: 16px;

    border: 1px solid #F1F0F0;

    &:hover {
      background-color: #f7f7f8;
    }
  }

  &.filters {
    background-color: #ecf5ff;
  }
}

.layout-wrapper {
  display: flex;
  align-items: center;
}

.header-clear {
  display: flex;
  align-items: center;
  height: 36px;
  margin-left: auto;
  padding: 8px;
  box-sizing: border-box;
}

.action-button {
  width: inherit;
  display: flex;
  border-radius: 0;

  &._reset {
    font-size: 13px;
    box-sizing: border-box;
    width: inherit;
    border: 1px solid $border-color;
    padding: 7px 10px;
    align-items: center;

    &._active {
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: $background-color-active;
      }
    }

    &:not(._active) {
      border-left: none;
    }
  }

  &._save {
    width: 100%;
    justify-content: center;
    border-radius: 0;
  }
}

.filters-panel-actions {
  display: flex;
  flex-flow: row;
  width: 280px;
}

.filter-panel__empty-block {
  width: 100%;
  flex: 1;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
}
</style>
