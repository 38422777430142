<template lang="pug">
.filter-layout(
  ref="reference"
  :class="[(!!value || (hasSort && isActive)) && '_active', type]"
)
  .icon-wrapper(v-if="hasSort")
    icon-button(
      :size="16"
      :icon="getSortingIcon(sorting?.[sortIndex]?.order || '')"
      :tooltip-title="type === 'simple' ? '' : tooltip"
      @click="setNextSorting(sortName, sorting?.[sortIndex]?.order || '')"
    )
    .badge(v-if="showSortIndex && sortIndex !== -1") {{ sortIndex + 1 }}
  .filter-content
    span.filter-name(:class="(value || tooltip) && '_small'" :title="label") {{ label }}
    span.filter-value(v-if="!rawValue && (value || tooltip)" :title="value || tooltip") {{ value || tooltip }}
    span.filter-value(v-else-if="rawValue && (value || tooltip)" v-html="value || tooltip")
  icon-button.filter-icon(v-if="hasFilter" :size="16" :icon="UiIconNames.Icon_Filter" @click="toggle")

  .floating(v-if="hasFilter && visible" ref="floating" :style="floatingStyles")
    slot(name="filter" :close="toggle")
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { useToggle } from "@/use/other/useToggle";
import { useFloating, shift, autoUpdate } from '@floating-ui/vue';
import { onClickOutside, useVModel } from '@vueuse/core'
import { getNextSortMode, getSortingIcon, setSorting } from "@/utils/filters/sorting";

import IconButton from "@/components/ui/button/IconButton.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

import type { PropType } from 'vue';
import type { SortItemI } from "@/utils/getters/defaultFilters";

export default defineComponent({
  name: "FilterLayout",
  components: {
    IconButton,
  },
  emits: [
    'update:sorting',
    'apply',
  ],
  methods: {
    getSortingIcon,
  },
  props: {
    type: {
      type: String as PropType<'default'|'simple'|'filters'>,
      default: 'default',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    placement: {
      type: String as PropType<'bottom-end'|'bottom-start'>,
      default: 'bottom-end',
    },
    hasSort: {
      type: Boolean,
      default: false,
    },
    hasFilter: {
      type: Boolean,
      default: false,
    },
    sortName: {
      type: String,
      default: '',
    },
    sorting: {
      type: Array as PropType<SortItemI[]>,
      default: () => [],
    },
    showSortIndex: {
      type: Boolean,
      default: false,
    },
    rawValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const reference = ref(null);
    const floating = ref(null);

    const { visible, hide, toggle } = useToggle();

    const { floatingStyles }
      = useFloating(reference, floating, { placement: props.placement, middleware: [shift()], whileElementsMounted: autoUpdate });

    if (props.hasFilter) onClickOutside(floating, hide, { ignore: [reference] });

    const sort = useVModel(props, 'sorting', context.emit);

    const sortIndex = computed(() => props.sorting.findIndex(f => f.title === props.sortName))
    const isActive = computed(() => sortIndex.value !== -1)

    const tooltip = computed(() => getSortingTooltip(props.sorting?.[sortIndex.value]?.order || ''))

    function setNextSorting(field: string, mode: 'asc'|'desc'|''|undefined) {
      const nextMode = getNextSortMode(mode);

      sort.value = setSorting(field, nextMode, props.type === 'filters' ? sort.value : []);
      context.emit('apply')
    }

    function getSortingTooltip(mode: 'asc'|'desc'|'') {
      switch (mode) {
        case "desc": return "По убыванию";
        case "asc": return "По возрастанию";
        default: return "";
      }
    }

    return {
      toggle,
      visible,
      reference,
      floating,
      floatingStyles,
      isActive,
      sortIndex,
      tooltip,
      setNextSorting,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/fonts';

.filter-layout {
  @include label-13-16;

  display: flex;
  flex-flow: row;
  gap: 2px;
  align-items: center;
  width: inherit;
  box-sizing: border-box;

  &.simple {
    @include label-12-16;
    color: #606266;
    padding: 8px;
    gap: 8px;

    .filter-name._small {
      font-size: 12px;
    }

    .filter-value {
      color: var(--main-color-black);
    }

    :deep(.icon-button) {
      padding: 0;
    }
  }

  &.default,
  &.filters {
    width: 100%;

    :deep(.filter-icon) {
      margin-left: auto;
    }
  }

  &.default {
    font-size: 13px;
    line-height: 16px;

    padding: 8px 8px;

    border: 1px solid #F1F0F0;
    background-color: var(--main-color-white);

    &:hover {
      background-color: #f7f7f8;
    }

    :deep(.icon-button) {
      color: var(--main-text-color);
    }

    &._active {
      border-left: 2px solid #409eff;
      background-color: #f7f7f8;
    }
  }

  &.filters {
    font-size: 13px;
    line-height: 16px;

    padding: 8px 8px;

    border: 1px solid #c6e2ff;
    background-color: #ecf5ff;

    &:hover {
      background-color: #d9ecff;
    }

    :deep(.icon-button) {
      color: var(--main-text-color);
    }

    &._active {
      border-left: 2px solid var(--main-color-blue);
      background-color: #d9ecff;
    }
  }

  .filter-content {
    display: flex;
    flex-flow: column;
    gap: 2px;
    height: 36px;
    justify-content: center;
    overflow: hidden;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .filter-name {
    font-weight: 500;

    &._small {
      font-size: 12px;
      font-weight: normal;
      color: #606266;
    }
  }

  .filter-value {
    font-size: 13px;
    font-weight: 500;
  }
}

.icon-wrapper {
  display: flex;
  position: relative;

  .badge {
    justify-content: center;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    top: 6px;
    right: 18px;
    user-select: none;
  }
}

.badge {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
}

.floating {
  z-index: 2;
}
</style>
